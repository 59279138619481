@use 'sass:color';

html.page {

	header,
	main {
		padding: 0 10px;
		max-width: 960px;
		margin: 0px auto;
		box-sizing: border-box;
	}

	main {
		padding-top: 20px;
	}

	article#hero {
		margin: 2em 0;
		background-color: color.scale($button-color, $lightness: -50%);
		background-image: url(/assets/img/guitars-pattern.png);
		border-width: 1px 0 1px 0;
		width: 100%;
		border-style: solid;
		border-color: $button-color;
		padding: 20px 10px;

		section {
			display: flex;
			column-gap: 20px;
			max-width: 960px;
			margin: 0px auto;
			box-sizing: border-box;
			justify-content: space-between;
			flex-wrap: wrap;

			#homepage-message {
				min-width: 320px;
				flex: 400px;
			}

			#homepage-example {
				flex: 320px;
			}
		}
	}

	article#homepage-sections {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: stretch;
		gap: 20px;

		div {
			flex: 450px;
			display: flex;

			flex-wrap: wrap;
			align-content: stretch;

			gap: 20px;

			section {
				padding: 10px;
				border-top: 4px solid $button-color;
				flex: 200px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				>div {
					display: block;
					flex: 1;

					h3 i {
						font-size: 120%;
						padding-right: 5px;
					}
				}

				>p {
					flex: 0;
					margin: 0;
					font-weight: 300;
				}
			}
		}
	}

	header {
		margin-top: 8px;
		padding-top: 64px;

		@media (max-width: 480px) {
			padding-top: 48px;
		}

		position: relative;

		h1 {
			position: absolute;
			top: 0;
			left: 10px;
			z-index: 2;
			display: block;

			a {
				display: block;
				font-size: 0 !important;
				height: 96px;
				width: 300px;
				background: url(/assets/img/rockstar-logotype.svg) left center no-repeat;

				@media (max-width: 580px) {
					background: url(/assets/img/rockstar-logotype-no-tail.svg) left center no-repeat;
				}

				@media (max-width: 480px) {
					height: 64px;
				}
			}
		}

		h2 {
			position: absolute;
			top: 0;
			right: 10px;
			font-weight: 100;
			height: 60px;
			margin: 0px 0 8px 0;
			max-width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			text-align: right;
			text-wrap: balance;
			max-width: calc(100% - 320px);
			font-size: 1.4em !important;

			@media (max-width: 640px) {
				max-width: 35%;
			}

			@media (max-width: 480px) {
				font-size: 1.1em !important;
				height: 40px;
			}
		}
	}

	nav {
		clear: both;
		padding: 0 10px;
		max-width: 960px;
		width: calc(100vw - 20px);
		margin: 0px auto;
		box-sizing: border-box;

		background: linear-gradient($button-color, color.scale($button-color, $lightness: -33%));
		background-color: $button-color !important;
		border-radius: 8px;
		border: 1px outset color.scale($button-color, $saturation: -33%);
		display: flex;
		justify-content: center;

		@media (max-width: 480px) {
			width: 100%;
			border-radius: 0;
			border-width: 1px 0 1px 0;
			padding: 0;
			margin: 0;
		}

		a {
			position: relative;
			z-index: 3;

			@media (max-width: $breakpoint-fullwidth) {
				font-size: 1.0em;
				padding: 8px 8px;
			}

			@media (max-width: 480px) {
				font-size: 1.0em;
				padding: 6px 6px;

				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}
			}

			font-weight: 500;
			font-size: 1.1em;
			color: $body-text-color;
			text-decoration: none;
			padding: 8px 16px;
			border-right: 1px outset color.scale($button-color, $saturation: -33%);
			border-left: 1px outset color.scale($button-color, $saturation: -33%);

			&:hover {
				background-color: color.scale($button-color, $lightness: -33%)
			}
		}
	}

	footer {
		margin-top: 20px;
		border-top: 1px solid $button-color;
		background: linear-gradient(color.scale($button-color, $lightness: -50%), #000);
		background-color: $button-color !important;
		padding: 20px;
		font-size: 0.8em;
		text-align: center;

		div {
			padding: 5px;
		}
	}
}